import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const UrlServicesContext = createContext();
const { Provider, Consumer } = UrlServicesContext;

function UrlServicesProvider({ children }) {
  /* ### API ### */
  //const [urlServices] = useState("http://192.168.0.5:3001/");
  const [urlServices] = useState("https://api.simplesas.com/");
  /*const [urlServices] = useState(
      "http://web.simple.aplios.software/api.simple/"
    );*/

  const [UserAuth, setUserAuth] = useState(() => {
    const storedSession = localStorage.getItem("Session");
    return storedSession
      ? { Session: storedSession }
      : {
          id: "",
          Session: null,
          name: "",
          lastname: "",
          allname: "",
          documentId: "",
          email: "",
          avatar: null,
          companyId: "",
          profile_id: "",
          profile_name: "",
          createdAt: "",
          gender: "",
          status: "",
          lastLogin: "",
        };
  });

  useEffect(() => {
    const fetchData = async () => {
      const datavalue = { token: localStorage.getItem("Session") };

      if (localStorage.getItem("Session") !== null) {
        try {
          const response = await axios.post(
            `${urlServices}users/validate-token`,
            datavalue
          );

          setUserAuth({
            ...UserAuth,
            id: response.data.user.id,
            name: response.data.user.name,
            lastname: response.data.user.lastName,
            allname:
              response.data.user.name + " " + response.data.user.lastName,
            documentId: response.data.user.documentId,
            email: response.data.user.email,
            avatar:
              response.data.user.avatar === ""
                ? null
                : response.data.user.avatar,
            companyId: response.data.user.companyId,
            profile_id: response.data.user.profileId,
            profile_name: response.data.user.profile.name,
            createdAt: response.data.user.createdAt,
            gender: response.data.user.genderId,
            status: response.data.user.status,
            lastLogin:
              response.data.user.logSystem.length > 0
                ? response.data.user.logSystem[0].createdAt
                : "",
          });
        } catch (error) {
          // Manejar errores aquí, por ejemplo, mostrar un mensaje de error o realizar alguna acción específica
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  const LoginAuth = (userData) => {
    setUserAuth({
      ...UserAuth,
      id: userData.user.id,
      Session: userData.token,
      name: userData.user.name,
      lastname: userData.user.lastName,
      allname: userData.user.name + " " + userData.user.lastName,
      documentId: userData.user.documentId,
      email: userData.user.email,
      avatar: userData.user.avatar === "" ? null : userData.user.avatar,
      companyId: userData.user.companyId,
      profile_id: userData.user.profileId,
      profile_name: userData.user.profile.name,
      createdAt: userData.user.createdAt,
      gender: userData.user.genderId,
      status: userData.user.status,
      lastLogin:
        userData.user.logSystem.length > 0
          ? userData.user.logSystem[0].createdAt
          : "",
    });
    localStorage.setItem("Session", userData.token);
  };

  const LogoutAuth = () => {
    setUserAuth({
      ...UserAuth,
      id: "",
      Session: null,
      name: "",
      lastname: "",
      allname: "",
      documentId: "",
      email: "",
      avatar: null,
      companyId: "",
      profile_id: "",
      profile_name: "",
      createdAt: "",
      gender: "",
      status: "",
      lastLogin: "",
    });
    localStorage.removeItem("Session");
  };
  const UpdateUserAuth = (newUserData) => {
    setUserAuth({
      ...UserAuth,
      ...newUserData,
    });
  };

  return (
    <Provider
      value={{
        urlServices,
        UserAuth,
        LoginAuth,
        LogoutAuth,
        UpdateUserAuth,
      }}
    >
      {children}
    </Provider>
  );
}

export {
  UrlServicesProvider,
  Consumer as urlServicesConsumer,
  UrlServicesContext,
};
